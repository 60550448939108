import { FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button, makeStyles, Grid, TextField, InputAdornment, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ICreateAddressDialogProps } from "./CreateAddressDialog.types";
import DoneIcon from '@material-ui/icons/Done';
import EditLocationRoundedIcon from '@material-ui/icons/EditLocationRounded';
import { COUNTRIES, ADDRESS_GROUPS, USER_ROLES, AU_STATES, NZ_STATES } from "../../../Types/Constants";
import HomeIcon from '@material-ui/icons/Home';
import { useCallback, useEffect, useMemo, useState } from "react";
import { Address } from "../../../Api/Address/Address";
import { toast } from "react-toastify";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../Redux";
import { isNil, set } from "lodash";
import MapAutocomplete from "../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../BP/MapAddress";
import { ICompanyInfo } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { setLoader } from "../../../Redux/Loader/actions";
import { Company } from "../../../Api/Company/Company";
import { isNumeric } from "../../../Utilities/Regex"
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EditIcon from '@material-ui/icons/Edit';
import PalletAccountV2 from "../../Common/PalletAccount/PalletAccount";
import DynamicPalletAccounts from "../../Common/PalletAccount/DynamicPallets";
import CountrySelect from "./CountrySelect";
import CustomerSelect, { PalletRestrictions, TimeslotRequiredSelect } from "./CustomerSelect";
import { Customer } from "../../../Api/Customer/Customer";
import { Formik, Form, FieldArray } from 'formik';
import { validationNewAddAddressSchema } from '../../../../src/ComponentsV2/lib/validationSchema';

const useStyles = makeStyles(() => ({
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    formControl: {
        minWidth: '100%',
        backgroundColor: 'white'
    }
}))

const { PHILIPPINES, AUSTRALIA, JAPAN, SINGAPORE, NEW_ZEALAND } = COUNTRIES

const {
    CUSTOMER,
    DC_OR_WAREHOUSE,
    EMPTY_PARK,
    PACK_OR_UNPACK_DEPOT,
    RAIL,
    WHARF,
    RESIDENTIAL
} = ADDRESS_GROUPS

const {
    ADMIN,
    SUPER_ADMIN,
    ADMIN_USER,
    COMPANY
} = USER_ROLES

const {
    WA,
    ACT,
    VIC,
    TAS,
    NSW,
    NT,
    QLD,
    SA
} = AU_STATES
const {
    AUCKLAND,
    HAWKES_BAY,
    WELLINGTON,
    TARANAKI,
    NELSON,
    CANTERBURY,
    OTAGO
} = NZ_STATES
const addressApi = new Address()

const companyApi = new Company()

const customerApi = new Customer()

// private customer = Customer

function CreateAddressDialog(props: ICreateAddressDialogProps) {
    const [t] = useTranslation('CreateAddressDialog')
    const classes = useStyles()
    const dispatch = useDispatch()
    const { mode } = props
    const user: IUserInfo | null = useSelector((state: AppState) => state.user.response)
    const isSuperAdmin = user?.type === SUPER_ADMIN || user?.type === ADMIN
    const isUserAdmin = user?.type === ADMIN_USER
    const isTransportCompany = user?.company.companyType == 'transport_company' //as bidding company
    const isIECompany = user?.company.companyType == 'import_exporter' //as post company

    // const [companyId, setCompanyId] = useState<number | undefined>(!isSuperAdmin ? user?.company?.id : undefined)
    const [companyId, setCompanyId] = useState<number | undefined>();

    const [companies, setCompanies] = useState<ICompanyInfo[]>([])
    const [isSeaFreight, setIsSeaFreight] = useState(false)
    const [isLandFreight, setIsLandFreight] = useState(true)
    const [country, setCountry] = useState(isSuperAdmin ? AUSTRALIA : (user?.company?.country ?? ''))
    const [customer, setCustomer] = useState<any>()
    const [customerList, setCustomerList] = useState<any>([])
    const [group, setGroup] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [email, setEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true);

    const [instruction, setInstruction] = useState('')
    const [receivingHours, setReceivingHours] = useState('')

    const [state, setState] = useState('')

    const [stateType, setStateType] = useState('')
    const [address, setAddress] = useState('');

    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [latitude, setLatitude] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')
    const [hasSearchAddress, setHasSearchAddress] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [mpdId, setMPDID] = useState('')

    const [losCam, setLosCam] = useState<any>();
    const [c, setC] = useState<any>();
    const [truckRequirement, setTruckRequirement] = useState<any>();
    const [cheapExchange, setCheapExchange] = useState<any>();
    const [timeSlotReq, setTimeSlotReq] = useState<any>();
    const [timeSlotBookingInfo, setTimeSlotBookingInfo] = useState<any>();

    const [isPreview, setIsPreview] = useState(false);
    const [isEditable, setIsEditable] = useState(true);



    const [shipId, setShipId] = useState<any>();
    const [palletRestrict, setPalletRestrict] = useState<any>();
    const [palletResInst, setPalletRestInt] = useState<any>();

    // const [palletAccountType, setPalletAccountType] = useState('');
    // const [palletAccountAction, setPalletAccountAction] = useState('');
    // const [palletAccountValue, setPalletAccountValue] = useState('');
    const [palletAccounts, setPalletAccounts] = useState([{ type: '', action: '', value: '' }]);

    const handlePalletAccountsChange = (newPalletAccounts) => {
        setPalletAccounts(newPalletAccounts);
    };

    const initialValues = {

        ship_id: "",
        company_id: user?.type == COMPANY ? user?.company?.id : "",
        group: "",
        registerAddress: '',


    };
    const [registerAddress, setRegisterAddress] = useState(initialValues?.registerAddress);

    const companyListOptions = useMemo(() => companies.map((company, i) => <MenuItem key={i} value={company.id}>{company.companyName}</MenuItem>), [companies])

    useEffect(() => {
        if (isUserAdmin && user?.country) {
            setCountry(user.country)
        }
    }, [isUserAdmin, user?.country])
    const toSnakeCase = (str) => {
        if (typeof str !== 'string') return '';
        return str.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '_');

    };


    const handleMapAddressSelect = useCallback(
        async (mapAddress: MapAddress, setFieldValue: (field: string, value: any) => void) => {
           
            if (!mapAddress) {
                // setRegisterAddress('');
                return;
            }
            setRegisterAddress(mapAddress.formattedAddress)
            setFieldValue('address', mapAddress.formattedAddress);
            setFieldValue('suburbCity', mapAddress.city);
            setFieldValue('postcode', mapAddress.postalCode);
            setFieldValue('stateRegionType', mapAddress.state);
            setFieldValue('latitude', mapAddress.lat);
            setFieldValue('longitude', mapAddress.long);
            setAddress(mapAddress.formattedAddress)
            if (mapAddress.country == 'NZ') {
                setCountry('new_zealand')
                setState(toSnakeCase(mapAddress.state))
            } else {
                setCountry('australia')
                setState(mapAddress.state)
            }

            setHasSearchAddress(true)
            setStreetAddress([mapAddress.streetNum, mapAddress.route].join(' '))

            setPostalCode(mapAddress.postalCode)
            setCity(mapAddress.city)
            setLatitude(String(mapAddress.lat))
            setLongitude(String(mapAddress.long))
        },
        [],
    )



    const handleCLearForm = (resetForm?: () => void) => {
        setEmail('');
        setCompanyId(user?.company?.id);
        setGroup('');
        setCountry(isSuperAdmin ? AUSTRALIA : (user?.company?.country ?? ''));
        setStreetAddress('');
        setInstruction('');
        setReceivingHours('');
        setState('');
        setCity('');
        setPostalCode('');
        setLatitude('');
        setLongitude('');
        setCompanyName('');
        setCompanyName('');
        setCountry('');
        setIsPreview(false);
        setIsEditable(true);
        setPalletAccounts([]);
        setMPDID('');
        setLosCam('');
        setC('');
        setTruckRequirement('');
        setCheapExchange('');
        setTimeSlotReq(false);
        setTimeSlotBookingInfo('');
        setStateType('');
        setShipId('');
        setPalletRestrict('');
        setPalletRestInt('');

        setAddress('');

        setCustomer('');
        setC('');
        setTimeSlotBookingInfo('');
        setTimeSlotReq('');
        setTruckRequirement('');
        if (resetForm) {

            resetForm();
        }
    }
    const handleCreateClick = async (resetForm: any) => {
    
        //Validations

        addressApi.create({
            address_group: group,
            city: city,
            email: email,
            country: country,
            postcode: postalCode,
            state: state,
            instruction: instruction,
            receiving_hours: receivingHours,
            street_number: streetAddress,
            company_id: user?.type == COMPANY ? user?.company?.id : companyId,
            latitude: latitude,
            longitude: longitude,
            is_common: props.isCommon ?? false,
            is_land_freight: isLandFreight,
            is_sea_freight: isSeaFreight,
            company_name: companyName,
            state_type: stateType,
            palletAccounts: palletAccounts,
            mpd_id: mpdId,
            customer_id: customer || undefined,
            Loscam: losCam,
            C: c,
            truck_requirements: truckRequirement,
            chep_exchange: cheapExchange,
            timeslot_required: timeSlotReq,
            timeslot_booking_info: timeSlotBookingInfo,
            registered_address: address,
            ship_id: shipId,
            pallet_restrictions: palletRestrict,
            pallet_restriction_instructions: palletResInst


        })
            .then((response: any) => {
                toast.success(t('success_creating_address'))
                // resetForm();
                handleCLearForm();
                if (props.onDone)
                    props.onDone(response.data);

                resetForm();



                handleCLearForm();
                // setEmail('');
                // setCompanyId(user?.company?.id);
                // setGroup('');
                // setCountry(isSuperAdmin ? AUSTRALIA : (user?.company?.country ?? ''));
                // setStreetAddress('');
                // setInstruction('');
                // setReceivingHours('');
                // setState('');
                // setCity('');
                // setPostalCode('');
                // setLatitude('');
                // setLongitude('');
                // setCompanyName('');
                // setCompanyName('');
                // setCountry('');
                // setIsPreview(false);
                // setIsEditable(true);
                // setPalletAccounts([]);
                // setMPDID('');
                // setLosCam('');
                // setC('');
                // setTruckRequirement('');
                // setCheapExchange('');
                // setTimeSlotReq(false);
                // setTimeSlotBookingInfo('');
                // setStateType('');
                // setShipId('');
                // setPalletRestrict('');
                // setPalletRestInt('');



                // setCustomer('');
                // setC('');
                // setTimeSlotBookingInfo('');
                // setTimeSlotReq('');
                // setTruckRequirement('');
            })
            .catch((err: any) => {
                console.log('err', err, err.response?.data)
                toast.error(err.response?.data ?? t('error_creating_address'))
            })
    }
    useEffect(() => {
        // Check if options are cached in local storage
        const cachedOptions = sessionStorage.getItem('addressOptions');

        if (JSON.parse(cachedOptions)?.length > 0) {
            setCompanies(JSON.parse(cachedOptions));
        } else {
            console.log('fetching data');
            // If options are not cached, fetch them from an API or other source
            fetchData();
        }


    }, []);


    useEffect(() => {
        if (companyId || user?.type == COMPANY) {


            customerApi.getCustomerById(companyId ?? user?.company?.id)
                .then((response) => {
                    const listCustomer: any = response.data
                    setCustomerList(listCustomer)

                    dispatch(setLoader(false))
                })
                .catch(err => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_get_company_list'))
                })

        }
    }, [companyId, dispatch, t, user?.company?.id, user?.type])


    const customerListOptions = useMemo(() => customerList.map((customersData, i) => <MenuItem key={i} value={customersData?.id}>{customersData?.first_name + ' ' + customersData?.last_name}</MenuItem>), [customerList])



    const fetchData = async () => {
        companyApi.getAll()
            .then((response) => {
                const list: ICompanyInfo[] = response.data
                sessionStorage.setItem('addressOptions', JSON.stringify(list));
                setCompanies(list)
                // if (isSuperAdmin) {
                //     setCompanyId(isSuperAdmin ? user?.company?.id : undefined)
                // } else {
                //     setCompanyId(undefined)
                // }
                dispatch(setLoader(false))
            })
            .catch(err => {
                dispatch(setLoader(false))
                console.log('err', err)
                toast.error(t('error_get_company_list'))
            })
    };

    useEffect(() => {
        if (props.type) {
            setIsLandFreight(props.type === 'transport')
            setIsSeaFreight(props.type === 'seafreight')
        }

    }, [props.type])

    const isJobClassificationDefined = Boolean(props.type)

    useEffect(() => {
        if (props.address) {
            addressApi.byId(props.address as any).then((response) => {
           
                const address: any = response.data[0];
                setGroup(address.address_group || '')
                setIsLandFreight(address.is_land_freight)
                setIsSeaFreight(address.is_sea_freight)
                setCountry(address.country)
                setStreetAddress(address.street_number)
                setInstruction(address.instruction)
                setReceivingHours(address.receiving_hours)
                setEmail(address.email || '')
                setState(address.state)
                setCity(address.city)
                setPostalCode(address.postcode)
                setLatitude(address.latitude)
                setLongitude(address.longitude)
                setCompanyName(address.company_name)
                setMPDID(address.mpd_id)
                setIsPreview(true)
                setIsEditable(false)
                setStateType(address.state_type)
                setCompanyId(address.company_id)
                setPalletAccounts(address.pallet_configurations)


                setCustomer(address?.customer_id)

                setLosCam(address?.Loscam);
                setC(address?.C);
                setTruckRequirement(address?.truck_requirements);
                setCheapExchange(address?.chep_exchange);
                setTimeSlotReq(address?.timeslot_required);
                setTimeSlotBookingInfo(address?.timeslot_booking_info);
                setStateType(address?.state_type);
                setAddress(address?.registered_address);

                setShipId(address?.ship_id);
                setPalletRestrict(address?.pallet_restrictions);
                setPalletRestInt(address?.pallet_restriction_instructions);

                dispatch(setLoader(false));
            })
                .catch((err: any) => {
                    console.log('err', err);
                    toast.error(t('error_getting_addresses'));
                    dispatch(setLoader(false));
                });
        } else {
            setEmail('')
            setCompanyId(undefined)
            setGroup('')
            setCountry(isSuperAdmin ? AUSTRALIA : (user?.company?.country ?? ''))
            setStreetAddress('')
            setInstruction('')
            setReceivingHours('')
            setState('')
            setCity('')
            setPostalCode('')
            setLatitude('')
            setLongitude('')
            setCompanyName('')
            setCompanyName('')
            setIsPreview(false)
            setIsEditable(true)
            setPalletAccounts([])

            setShipId('');
            setPalletRestrict('');
            setPalletRestInt('');

        }
    }, [props.address, isSuperAdmin, user, dispatch, t])

    const handleEditClick = useCallback(
        (resetForm: any, setFieldValue: any) => {
            setIsEditable(true);

            if (props.address) {
                addressApi.byId(props.address as any).then((response) => {
                    const address: any = response.data[0];
                    setFieldValue('group', address.address_group || '');
                    setFieldValue('company_id', address.company_id);
                    setFieldValue('ship_id', address.ship_id);
                })
            }
        },
        [props.address],
    )
    const handleUpdateClick = (resetForm: () => void) => {
        const addressData = props.address
        if (!isValidEmail) {
            toast.warn("Please enter a valid email address")
            return
        }
        if (addressData) {
            dispatch(setLoader(true))
            addressApi.update({
                addressId: addressData as any,
                address_group: group,
                city: city,
                email: email,
                country: country,
                postcode: postalCode,
                state: state,
                street_number: streetAddress,
                instruction: instruction,
                receiving_hours: receivingHours,
                company_id: companyId,
                latitude: latitude,
                longitude: longitude,
                is_common: props.address.is_common ?? false,
                is_land_freight: isLandFreight,
                is_sea_freight: isSeaFreight,
                company_name: companyName,
                state_type: stateType,
                palletAccounts: palletAccounts,
                mpd_id: mpdId,


                customer_id: customer,
                Loscam: losCam,
                C: c,
                truck_requirements: truckRequirement,
                chep_exchange: cheapExchange,
                timeslot_required: timeSlotReq,
                timeslot_booking_info: timeSlotBookingInfo,
                registered_address: address,

                ship_id: shipId,
                pallet_restrictions: palletRestrict,
                pallet_restriction_instructions: palletResInst

            })
                .then((response: any) => {
                    dispatch(setLoader(false))
                    toast.success(t('success_updating_address'))
                    handleCLearForm();
                    if (resetForm) {

                        resetForm();
                    }
                    props.handleClose({
                        palletAccounts: palletAccounts,
                        receiving_hours: receivingHours,
                        instruction: instruction,
                    })
                })
                .catch(err => {
                    console.log(`err`, err)
                    dispatch(setLoader(false))
                    toast.error(err.response?.data ?? t('error_updating_address'))
                })
        }
    }
    const handleEmailChange = (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);

        // Custom email validation logic
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(enteredEmail);
        setIsValidEmail(isValid);
    };


    return (
        <>

            <Formik
                initialValues={initialValues}
                // onSubmit={handleCreateClick}
                onSubmit={(values, { resetForm }) => {
                    handleCreateClick(resetForm)
                }}
                enableReinitialize={true}
                validationSchema={validationNewAddAddressSchema}
            >
                {({ isSubmitting, handleChange, setFieldValue, handleBlur, values, errors, touched, setTouched, resetForm }) => {


                    return <Dialog maxWidth="md" fullWidth open={props.open} onClose={props.handleClose}>
                        <DialogTitle>
                            <Typography>
                                {(mode == 'create') ? t('add_new_address') : t('edit_address')}

                            </Typography>
                        </DialogTitle>
                        <Form>
                            <DialogContent dividers>
                                <Grid
                                    container
                                    spacing={3}
                                >


                                    <Grid md={4} item>
                                        <FormControl fullWidth style={{ backgroundColor: 'white' }} variant="outlined" >
                                            <InputLabel style={{ fontWeight: 'bold', backgroundColor: 'white', padding: '0px 10px' }}>{t('group') + '*'}</InputLabel>
                                            <Select
                                                disabled={isPreview && !isEditable}
                                                value={values?.group || group}
                                                name="group"
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => {
                                                    setGroup(e.target.value as string);
                                                    // setFieldValue("group", e.target.value as string);
                                                    // setTouched({ ...touched, group: true });
                                                    // console.log(e.target.value, values, 'YYYYYYY', touched.group)

                                                    handleChange(e);
                                                    // setFieldValue("group", e.target.value);
                                                }}
                                                onBlur={handleBlur}

                                            >
                                                <MenuItem value={CUSTOMER}>{t(CUSTOMER)}</MenuItem>
                                                <MenuItem value={DC_OR_WAREHOUSE}>{t(DC_OR_WAREHOUSE)}</MenuItem>
                                                <MenuItem value={EMPTY_PARK}>{t(EMPTY_PARK)}</MenuItem>
                                                <MenuItem value={PACK_OR_UNPACK_DEPOT}>{t(PACK_OR_UNPACK_DEPOT)}</MenuItem>
                                                <MenuItem value={RAIL}>{t(RAIL)}</MenuItem>
                                                <MenuItem value={WHARF}>{t(WHARF)}</MenuItem>
                                                <MenuItem value={RESIDENTIAL}>{t(RESIDENTIAL)}</MenuItem>


                                            </Select>
                                            <FormHelperText style={{ color: "#f44336" }}>{errors.group}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {
                                        isSuperAdmin && !props.isCommon
                                            ?
                                            ((props.mode == 'create') ?
                                                < Grid md={4} item>
                                                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                                        <InputLabel style={{ fontWeight: 'bold', backgroundColor: 'white', padding: '0px 10px' }}>{"Company/Customer Name" + '*'}</InputLabel>
                                                        <Select
                                                            disabled={isPreview && !isEditable}
                                                            value={values?.company_id || companyId}
                                                            name="company_id"
                                                            style={{ backgroundColor: 'white' }}
                                                            onChange={(e) => {
                                                                const selectedCompanyId = e.target.value as number
                                                                // touched.companyId = true
                                                                handleChange(e)
                                                                // setFieldValue("companyId", selectedCompanyId);
                                                                setCompanyId(selectedCompanyId)
                                                            }}
                                                            onBlur={handleBlur}
                                                        >
                                                            <MenuItem  >
                                                                {'Select Company'}
                                                            </MenuItem>
                                                            {companyListOptions}
                                                        </Select>
                                                        <FormHelperText style={{ color: "#f44336" }}>{errors.company_id}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                : null

                                            )
                                            : null
                                    }









                                    <Grid md={4} item>
                                        <CustomerSelect

                                            data={customerListOptions}
                                            value={customer ? customer : ''}
                                            onChange={(e) => setCustomer(e.target.value as string)}
                                            disabled={isPreview && !isEditable}
                                            isPreview={isPreview}
                                            isEditable={isEditable}
                                            t={t}
                                        />
                                    </Grid>


                                    <Grid md={12} item>
                                        <MapAutocomplete
                                        setFieldValue={setFieldValue}
                                            //  onAddressSelect={handleMapAddressSelect}
                                            onAddressSelect={(mapAddress) => handleMapAddressSelect(mapAddress, setFieldValue)}
                                            setAddress={setAddress} address={address} readOnly={!isEditable} 
                                            // error={touched.registerAddress && !!errors.registerAddress} 
                                            helperText={touched.registerAddress && errors.registerAddress} 
                                            />


                                    </Grid>


                                    <Grid md={3} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={values?.ship_id || shipId}
                                            // inputMode="numeric"
                                            onChange={e => {
                                                const trimmedValue = e.target.value.trim();
                                                handleChange(e);
                                                if (/^[a-zA-Z0-9]*$/.test(trimmedValue)) {
                                                    // Valid input: either empty or alphanumeric
                                                    setShipId(trimmedValue);

                                                    // setTouched({ ...touched, ship_id: true });
                                                    // setFieldValue("ship_id", e.target.value);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            fullWidth
                                            label="Ship ID*"
                                            name="ship_id"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        // inputProps={{
                                        //     inputMode: 'numeric',
                                        //     pattern: '[0-9 ]*', // Ensure the input is numeric only or spaces
                                        // }}
                                        />
                                        <FormHelperText style={{ color: "#f44336" }}>{errors.ship_id}</FormHelperText>

                                    </Grid>



                                    <Grid md={3} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={c}
                                            onChange={e => setC(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('C/-')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <TextFieldsIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid md={6} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={streetAddress}
                                            onChange={(e) => setStreetAddress(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('address')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <HomeIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>



                                    <Grid md={4} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            name="suburbCity"
                                            label={"Suburb"}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <HomeIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>


                                    <Grid md={4} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={postalCode}
                                            onChange={(e) => setPostalCode(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('postal_code')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <HomeIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {
                                        country === AUSTRALIA
                                            ? <>
                                                <Grid md={4} item>
                                                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                                        <InputLabel style={{ fontWeight: 'bold', backgroundColor: 'white', padding: '0px 10px' }}>State</InputLabel>
                                                        <Select
                                                            disabled={isPreview && !isEditable}
                                                            value={state}
                                                            style={{ backgroundColor: 'white' }}
                                                            onChange={(e) => setState(e.target.value as string)}
                                                        >
                                                            <MenuItem value={WA}>{t(WA)}</MenuItem>
                                                            <MenuItem value={ACT}>{t(ACT)}</MenuItem>
                                                            <MenuItem value={VIC}>{t(VIC)}</MenuItem>
                                                            <MenuItem value={TAS}>{t(TAS)}</MenuItem>
                                                            <MenuItem value={NSW}>{t(NSW)}</MenuItem>
                                                            <MenuItem value={NT}>{t(NT)}</MenuItem>
                                                            <MenuItem value={QLD}>{t(QLD)}</MenuItem>
                                                            <MenuItem value={SA}>{t(SA)}</MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                {/* <Grid md={4} item>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel style={{ fontWeight: 'bold' }}>{t('state_type')}</InputLabel>
                                        <Select
                                            disabled={isPreview && !isEditable}
                                            value={stateType}
                                            style={{backgroundColor:'white'}}
                                            onChange={(e) => setStateType(e.target.value as string)}
                                        >
                                            <MenuItem value='inner_metro'>{t('inner_metro')}</MenuItem>
                                            <MenuItem value='outer_metro'>{t('outer_metro')}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                            </>
                                            :
                                            <Grid md={4} item>
                                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                                    <InputLabel style={{ fontWeight: 'bold', backgroundColor: 'white', padding: '0px 10px' }}>{t('state')}</InputLabel>
                                                    <Select
                                                        disabled={isPreview && !isEditable}
                                                        value={state}
                                                        style={{ backgroundColor: 'white' }}
                                                        onChange={(e) => setState(e.target.value as string)}
                                                    >
                                                        <MenuItem value={AUCKLAND}>{t(AUCKLAND)}</MenuItem>
                                                        <MenuItem value={HAWKES_BAY}>{t(HAWKES_BAY)}</MenuItem>
                                                        <MenuItem value={WELLINGTON}>{t(WELLINGTON)}</MenuItem>
                                                        <MenuItem value={TARANAKI}>{t(TARANAKI)}</MenuItem>
                                                        <MenuItem value={NELSON}>{t(NELSON)}</MenuItem>
                                                        <MenuItem value={CANTERBURY}>{t(CANTERBURY)}</MenuItem>
                                                        <MenuItem value={OTAGO}>{t(OTAGO)}</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                    }
                                    <Grid md={4} item>
                                        <TimeslotRequiredSelect
                                            value={timeSlotReq}
                                            onChange={(e) => setTimeSlotReq(e.target.value as string)}
                                            disabled={isPreview && !isEditable}
                                            isPreview={isPreview}
                                            isEditable={isEditable}
                                            t={t}
                                        />
                                    </Grid>


                                    <Grid md={8} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={timeSlotBookingInfo}
                                            onChange={e => setTimeSlotBookingInfo(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('TimeSlot booking information')}
                                            // multiline
                                            // style={{height: '52px'}}
                                            rows={4}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <TextFieldsIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>


                                    <Grid md={6} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={receivingHours}
                                            onChange={(e) => setReceivingHours(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('receiving_hours')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <HomeIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>


                                    <Grid md={6} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={truckRequirement}
                                            onChange={e => setTruckRequirement(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('Truck Requirement')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <TextFieldsIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>




                                    <Grid md={3} item>
                                        <PalletRestrictions
                                            value={palletRestrict}
                                            onChange={(e) => setPalletRestrict(e.target.value as string)}
                                            disabled={isPreview && !isEditable}
                                            isPreview={isPreview}
                                            isEditable={isEditable}
                                            t={t}
                                        />
                                    </Grid>

                                    <Grid md={4} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={palletResInst}
                                            onChange={(e) => setPalletRestInt(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('Pallet Restrictions Instructions')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <HomeIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid md={5} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={instruction}
                                            onChange={(e) => setInstruction(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('instruction')}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <HomeIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid md={12} item>
                                        <DynamicPalletAccounts palletAccounts={palletAccounts} onPalletAccountsChange={handlePalletAccountsChange} readOnly={isPreview && !isEditable} />
                                    </Grid>

                                    
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={longitude}
                                            onChange={(e) => setLongitude(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('longitude') + '*'}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <EditLocationRoundedIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>


                                    <Grid md={6} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={latitude}
                                            onChange={(e) => setLatitude(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('latitude') + '*'}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <EditLocationRoundedIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {
                                    !isPreview && isEditable
                                        ? <Button
                                            // onClick={handleCreateClick} 
                                            className={classes.btnCreate} startIcon={<DoneIcon />}
                                            // onClick={()=>handleCreateClick(resetForm)}  
                                            type='submit' variant="contained">
                                            {t('create')}
                                        </Button>
                                        : null
                                }
                                {
                                    isPreview && !isEditable
                                        ? <Button onClick={() => handleEditClick(resetForm, setFieldValue)} color="primary" startIcon={<EditIcon />} variant="contained">
                                            {t('edit')}
                                        </Button>
                                        : null
                                }
                                {
                                    (isPreview && isEditable)
                                        ? <Button onClick={() => handleUpdateClick(resetForm)} className={classes.btnCreate} startIcon={<DoneIcon />} variant="contained">
                                            {t('update')}
                                        </Button>
                                        : null
                                }

                                <Button variant="contained" onClick={() => { props.handleClose(); handleCLearForm(resetForm) }}>
                                    {t('cancel')}
                                </Button>

                            </DialogActions>
                        </Form>
                    </Dialog >

                }}

            </Formik>
        </>
    )
}


export default CreateAddressDialog