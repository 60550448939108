import * as Yup from "yup";
const nameRegex = /^[a-zA-Z\s]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
const zipCodeRegex = /^\d{5,6}$/;
export const validationCreateJobSchema = Yup.object({
  company: Yup.string().required("This field is required."),
  movementType: Yup.string().required("This field is required."),
  shipmentNo: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(20, "This field cannot exceed 20 characters."),
  customer: Yup.string().optional(),
  jobType: Yup.string().required("This field is required."),
  m141: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  customerReference: Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  tripType: Yup.string().required("This field is required."),
  transportType: Yup.string().required("This field is required."),
  temperature: Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  groupType: Yup.string().optional(),
  goodsDesc:Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(50, "This field cannot exceed 50 characters."),
  totalQty: Yup.number().optional(),
  netWeight: Yup.number().optional(),
  palletAcc:Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  palletAccNumber: Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  palletAccType:Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  grossWeight: Yup.number().optional(),
  volume: Yup.number().optional(),
  palletAccAction: Yup.string().optional(),
  containerNo:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  wharfSlot:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  sealNo: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  cntrlfd:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  ecSealNo: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  declaredGrossWeight:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  document: Yup.string().optional(),
  serviceType: Yup.string().optional(),
  jobServiceCharges: Yup.string().optional(),
  postTo: Yup.string().optional(),
  buyPrice:  Yup.number().optional(),
  sellPrice:  Yup.number().optional(),
  jobInstruction:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  containerOverwight: Yup.boolean().optional(),
  jobDetails: Yup.array().of(
    Yup.object().shape({
      jobDetailPackage: Yup.string().optional(),
      jobDetailQty: Yup.number().optional(),
      jobDetailSKU:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(15, "This field cannot exceed 15 characters."),
      jobDetailDesc: Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(50, "This field cannot exceed 50 characters."),
      jobDetailLot:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true),
      jobDetailHeight: Yup.number().optional(),
      jobDetailWidth: Yup.number().optional(),
      jobDetailLength: Yup.number().optional(),
      jobDetailNetWeight:  Yup.number().optional(),
    })
  ),
  jobDetailVesselName:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  jobDetailETA: Yup.date().optional(),
  jobDetailVoyage:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  trip: Yup.array().of(
    Yup.object().shape({
      tripPickVehicleType: Yup.string().required("This field is required."),
      tripPickGroupType: Yup.string().required("This field is required."),
      tripPickCountry: Yup.string().required("This field is required."),
      tripPickState: Yup.string().required("This field is required."),
      tripPickShipId: Yup.string().required("This field is required."),
      tripPickAddress: Yup.string().required("This field is required."),
      tripPickDate: Yup.date().required("This field is required."),
      tripPickTime: Yup.string().required("This field is required."),
      tripPickJobRequirement:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(150, "This field cannot exceed 150 characters."),
      tripDeliverVehicleType: Yup.string().required("This field is required."),
      tripDeliverGroupType: Yup.string().required("This field is required."),
      tripDeliverCounty: Yup.string().required("This field is required."),
      tripDeliverState: Yup.string().required("This field is required."),
      tripDeliverShipId: Yup.string().required("This field is required."),
      tripDeliverAddress: Yup.string().required("This field is required."),
      tripDeliverDate: Yup.date().required("This field is required."),
      tripDeliverTime: Yup.string().required("This field is required."),
      tripDeliverJobRequirement:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(150, "This field cannot exceed 150 characters."),
    })
  ),
});

export const validationAddContainerModal = Yup.object({
  container_no: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    seal_no: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    declared_gross_weight: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    wharf_slot: Yup.date()
    .required("This field is required."),
    
    empty_container_release_number: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    cntr_last_day: Yup.date()
    .required("This field is required."),
});


export const validationJobTypeModal = Yup.object({
  jobtype: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
});


export const validationTypeofPackageModal = Yup.object({
  typeofpackage: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
});


export const validationCompanyRegister = Yup.object({
  companyName: Yup.string()
    .matches(/^[a-zA-Z0-9 ,.\-]+$/, 'Invalid company name. Only alphanumeric and special characters are allowed.')
    .required('This field is required.'),
  TradingName: Yup.string()
    .matches(/^[a-zA-Z0-9 ,.\-]+$/, 'Invalid trading name. Only alphanumeric characters and specific special characters are allowed.'),
  Abn_Acn: Yup.string()
    .matches(/^\d{1,11}$/, 'ABN/ACN must be a maximum of 11 digits')
    .required('This field is required.'),
  mobile: Yup.string()
    .matches(/^\d{6,10}$/, 'Invalid phone number. It must be between 6 to 10 digits.')
    .required('This field is required.'),
  // email: Yup.string()
  //   .email('Invalid email address')
  //   .required('This field is required.'),
  password: Yup.string()
    .required('This field is required.'),

  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('This field is required.'),
    companyType: Yup.string()
    .required('This field is required.'),
  // firstName: Yup.string()
  //   .required('This field is required.'),

  // lastName: Yup.string()
  //   .required('This field is required.'),

  TypeOfBussiness: Yup.string()
    .required('This field is required.'),
  ProductsHandled: Yup.string()
    .matches(/^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':",./<>?|`~\\-]*$/, 'Only alphanumeric and special characters are allowed')
    .max(200, 'Maximum 200 characters allowed')
    .required('This field is required.'),

  contactEmail: Yup.string()
    .required('This field is required.'),

  contactPhone: Yup.string()
    .matches(/^\d{6,10}$/, 'Invalid phone number. It must be between 6 to 10 digits.')
    .required('This field is required.'),
  contactName: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s.'-]*$/,
      'Contact name can only include letters, numbers, spaces, periods, apostrophes, and hyphens.'
    )
    .required('This field is required.'),
  adminEmail: Yup.string()
    .required('This field is required.'),

  // adminPhone: Yup.string()
  //   .matches(/^\d{6,10}$/, 'Invalid phone number. It must be between 6 to 10 digits.')
  //   .required('This field is required.'),
  adminName: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s.'-]*$/,
      'Contact name can only include letters, numbers, spaces, periods, apostrophes, and hyphens.'
    )
    .required('This field is required.'),
    operationscreditlimit:Yup.number()
    .typeError('Operations Credit Limit must be a number')
    .positive('Operations Credit Limit must be a positive number')
    .required('This field is required.'),
  operationscredit: Yup.number()
    .typeError('Operations Credit Limit must be a number')
    .positive('Operations Credit Limit must be a positive number')
    .required('This field is required.'),
  authorisedName: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-]+$/, 'Authorised Name can only contain alphanumeric characters, spaces, and hyphens')
    .required('This field is required.'), 

  position: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-']+$/, 'Position can only contain alphanumeric characters, spaces, hyphens, and apostrophes')
    .required('This field is required.'),

    registerAddress: Yup.string()
    .required('This field is required.'),

    authorisedRepresentativeName: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-]+$/, 'Authorised Name can only contain alphanumeric characters, spaces, and hyphens')
    .required('This field is required.'),

    positionTitle: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-']+$/, 'Position can only contain alphanumeric characters, spaces, hyphens, and apostrophes')
    .required('This field is required.'),
    
    selectedOptions: Yup.array()
    .min(1, 'At least one checkbox must be selected.')
    .required('At least one checkbox must be selected.'),
});

export const validationPDFJobSchema = Yup.object({
  email: Yup.string()
    .required('This field is required.')
    .matches(emailRegex, 'Invalid email address') 
    .email('Invalid email address'),
});

export const validationAddAddressSchema = Yup.object({
  groupType: Yup.string().required("This field is required."),
  company: Yup.string().required("This field is required."),
  ship_id: Yup.string()
  .required("This field is required.")
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
});



export const validationNewAddAddressSchema = Yup.object({
  ship_id: Yup.string()
  .required("This field is required.")
  .max(60, "This field cannot exceed 60 characters."),
  group: Yup.string().required("This field is required."),
  company_id: Yup.string().required("This field is required."),
  registerAddress: Yup.string().required("This field is required."),
});