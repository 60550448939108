import { useState } from 'react';
import ModalWrapper from '../wrappers/ModalWrapper';
import { Button, makeStyles, IconButton, Typography, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';
import { WebStorage } from '../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';

interface UploadPodModalProps {
    open: boolean;
    close: () => void;
    fetchJobs: () => void;
    assignedData?: any;
}

const storage = new WebStorage();

const useStyles = makeStyles((theme) => ({
    dropzone: {
        border: '2px dashed #e0e0e0',
        borderRadius: '20px',
        padding: '30px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border-color 0.3s, background-color 0.3s ease-in-out, transform 0.2s',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            transform: 'scale(1.01)',
        },
    },
    dropzoneActive: {
        borderColor: theme.palette.primary.main,
        backgroundColor: '#f0f8ff',
    },
    filePreview: {
        marginTop: '0',
        padding: '20px',
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '24px',
        color: '#333',
        marginBottom: '20px',
    },
    instructions: {
        fontSize: '16px',
        color: theme.palette.text.secondary,
        marginBottom: '10px',
        letterSpacing: '0.5px',
    },
    fileIcon: {
        fontSize: '18px',
        color: theme.palette.error.main,
    },
    imageContainer: {
        margin: "20px 0"
    }
}));

const UploadPodModal = ({ open, close, assignedData, fetchJobs }: UploadPodModalProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFiles([acceptedFiles[0]]);
        }
        setIsDragging(false);
    };

    const onDragEnter = () => setIsDragging(true);
    const onDragLeave = () => setIsDragging(false);

    const removeFile = (fileToRemove: File) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragEnter,
        onDragLeave,
        multiple: false,
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (files.length === 0) {
            toast.error('Please upload at least one file');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('jobId', assignedData?.id);
        formData.append('file', files[0]);
        try {
            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/v3/job/upload/receipt', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
                },
                body: formData,
            });

            const result = await response.json();

            if (result.status_code === 201 && result.success) {
                toast.success('POD uploaded successfully');
                fetchJobs();
                handleClose();
            } else {
                toast.error(result.message || 'Failed to upload POD');
            }
        } catch (error) {
            toast.error('Error uploading POD: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        if (!loading) {
            setFiles([]);
            close();
        }
    };

    return (
        <ModalWrapper open={open} onClose={handleClose} heading="Upload POD" >
            <form onSubmit={handleSubmit}>
                <div
                    {...getRootProps({
                        className: `${classes.dropzone} ${isDragging ? classes.dropzoneActive : ''}`,
                    })}
                >
                    <input {...getInputProps()} />
                    <Typography className={classes.instructions}>
                        Drag & drop files here, or click to select files
                    </Typography>
                    <Typography className={classes.instructions}>
                        (Only *.jpeg, *.png, *.jpg, *.pdf formats are accepted)
                    </Typography>
                </div>

                {files.length > 0 && (
                    <div className={classes.filePreview}>
                        <Typography variant="subtitle1">Uploaded File:</Typography>
                        <ul >
                            {files.map((file, index) => (
                                <li key={index} >
                                    <span>{file.name}</span>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => removeFile(file)}
                                        className={classes.fileIcon}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className={classes.imageContainer}>
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Uploading...' : 'Submit'}
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    );
};

export default UploadPodModal;
