import { makeStyles, Container, Grid, Typography, Box, TextField, InputAdornment, Button, IconButton } from "@material-ui/core"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moveit4u_logo from '../../../Images/moveit4u-logo.png'
import { UsersApi } from "../../../Api/Users/Users";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles(() => ({
    box: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)'
    },
    center: {
        textAlign: 'center'
    },
    logo: {
        width: '150px',
        height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    btnBack: {
        backgroundColor: 'grey',
        color: 'white',
        '&:hover': {
          backgroundColor: 'grey',
        },
      },
}))

const userApi = new UsersApi()

function ForgotPasswordChange() {
    const [t] = useTranslation('ForgotPasswordChange')
    const history = useHistory()
    const dispatch = useDispatch()
    const classes = useStyles()
    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [showPass, setShowPass] = useState<boolean>(false)
    const [token, setToken] = useState('')


    const handleBackButtonClick = () => {
        history.push(routes.landing.login[0]);
      };

    const handleOnChangePasswordClick = useCallback(
        () => {
            dispatch(setLoader(true))
            userApi.changeForgottenPassword({
                newPassword,
                passwordConfirm,
                token
            })
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_changing_password'))
                    history.push(routes.landing.login[0])
                })
                .catch(err => {
                    console.log(`err`, err)
                    toast.error(t(err.response?.data || 'error_changing_password'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, history, newPassword, passwordConfirm, token],
    )

    useEffect(() => {
        


        const hash = window.location.hash;

        if (hash) {
            
            const queryParams = hash.split('?')[1]; 
            
            const urlParams = new URLSearchParams(queryParams);
            const tokensss = urlParams.get('token');
            
            if (tokensss) {
              setToken(tokensss);
            }

        }

        
    }, [token])

    return (
        <>
            <Container maxWidth="sm">
                <Box className={classes.box}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid xs={12} item>
                            <img
                                className={classes.logo}
                                title="Moveit4u"
                                src={moveit4u_logo}
                                alt="Moveit4u"
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Typography variant="h6" className={classes.center} gutterBottom>
                                {t('please_enter_your_new_password')}
                            </Typography>
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('enter_password')}
                                type={showPass ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPass(!showPass)}>
                                                {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                value={passwordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('confirm_password')}
                                type={showPass ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPass(!showPass)}>
                                                {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid className={classes.center} item xs={12}>
                            <Button onClick={handleOnChangePasswordClick} fullWidth variant="contained" color="primary">
                                {t('change_password')}
                            </Button>
                        </Grid>
                        <Grid md={6} item>
                    <Button
                      startIcon={<ArrowBackIcon />}
                      className={classes.btnBack}
                      variant="contained"
                      fullWidth
                      onClick={handleBackButtonClick}
                    >
                      {'Back to Login in' }
                    </Button>
                  </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default ForgotPasswordChange