import React, { useEffect } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Select, TextField, InputAdornment } from '@material-ui/core';
import TextFieldsIcon from '@material-ui/icons/TextFields';

function PalletAccount({ palletAccount, onChange, disabledOptions, readOnly }) {
    const { type, action, value } = palletAccount;

    const handleTypeChange = (event) => {
        onChange('type', event.target.value);
    };

    const handleActionChange = (event) => {
        onChange('action', event.target.value);
        // const newAction = event.target.value;
        // const newValue = newAction === 'PLAIN' && action === 'transfer' ? 'Plain Pallet' : value;
        // onChange('value', newValue);
    };

    const handleValueChange = (event) => {
        onChange('value', event.target.value);
    };


    // Determine if the given type-action pair is disabled
    const isTypeActionDisabled = (palletType, palletAction) => {
        return disabledOptions.some(option => option.type === palletType && option.action === palletAction);
    };

    // Determine if all actions for a given type are disabled
    const areAllActionsDisabled = (palletType) => {
        return ['transfer', 'exchange'].every(action => isTypeActionDisabled(palletType, action));
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid md={4} item>
                <FormControl variant="outlined" fullWidth >
                    <InputLabel style={{ fontWeight: 'bold' , backgroundColor: 'white', padding: '0px 10px'}}>Pallet Account Type</InputLabel>
                    <Select style={{ backgroundColor: 'white' }} disabled={readOnly} value={type} onChange={handleTypeChange}>
                        <MenuItem value="">Select Type</MenuItem>
                        <MenuItem
                            value="CHEPS"
                            disabled={areAllActionsDisabled('CHEPS')}
                        >
                            Cheps
                        </MenuItem>
                        <MenuItem
                            value="LOSCAMS"
                            disabled={areAllActionsDisabled('LOSCAMS')}
                        >
                            Loscams
                        </MenuItem>
                        <MenuItem
                            value="PLAIN"
                            disabled={areAllActionsDisabled('PLAIN')}
                        >
                            Plain
                        </MenuItem>




                        <MenuItem
                            value="CHEPS PLASTIC"
                            disabled={areAllActionsDisabled('CHEAPS PLASTIC')}
                        >
                           Cheps Plastic
                        </MenuItem>
                        <MenuItem
                            value="LOSCAMS PLASTIC"
                            disabled={areAllActionsDisabled('LOSCAMS PLASTIC')}
                        >
                            Loscams Plastic
                        </MenuItem>





                        <MenuItem
                            value="OTHER"
                            disabled={areAllActionsDisabled('OTHER')}
                        >
                            Other
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid md={4} item>
                <TextField
                    value={value}
                    // disabled={readOnly || action == 'exchange' || (type === 'PLAIN' && action === 'transfer')}
                    onChange={handleValueChange}
                    variant="outlined"
                    fullWidth
                    label="Pallet Account Number/Details"
                    InputLabelProps={{
                        style: {
                            fontWeight: 'bold'
                        }
                    }}
                />
            </Grid>
            <Grid md={4} item>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ fontWeight: 'bold' , backgroundColor: 'white', padding: '0px 10px'}}>Pallet Account Action</InputLabel>
                    <Select style={{ backgroundColor: 'white' }} disabled={readOnly} value={action} onChange={handleActionChange}>
                        <MenuItem value="">Select Action</MenuItem>
                        <MenuItem
                            value="transfer"
                            disabled={isTypeActionDisabled(type, 'transfer')}
                        >
                            Transfer
                        </MenuItem>
                        <MenuItem
                            value="exchange"
                            disabled={isTypeActionDisabled(type, 'exchange')}
                        >
                            Exchange
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
           
        </Grid>
    );
}

export default PalletAccount;
