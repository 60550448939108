import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import AssignJobModal from '../../modals/AssignJobModal';
import useDebounce from '../../hooks/useDebounce';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Redux';
import { Button, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { fetchJobsList, handleDownload } from '../../lib/helper';
import ViewJobModal from '../../modals/ViewJobModal';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { AdminModalsState } from './JobsToBeAssignedV2';
import UploadPodModal from '../../modals/UploadPodModal';

const useStyles = makeStyles(() => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    dataGrid: {
        minWidth: "fit-content",
        '& .MuiDataGrid-menuIconButton': {
            color: 'white',
        },
    },
    search: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "20px 0",
    },
    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    }
}));

const storage = new WebStorage();

const JobsToBeAssignedToCarrier = (props: any) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [assignedData, setAssignedData] = useState(null);
    const [search, setSearch] = useState("");
    const user = useSelector((state: AppState) => state.user.response);
    
    const [modals, setModals] = useState<AdminModalsState>({
        modal1: false,
        modal4: false,   //view modal
        modal2: false,   //pod modl
    });

    
    const [data, setData] = useState({
        loading: true,
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
        rows: [],
    });

    const token = `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchJobs();
    }, [debouncedSearch]);
   

    const fetchJobs = async () => {
        const jobsFetched = await fetchJobsList("carrier", "", "", debouncedSearch, setLoading, token);
        setData((prevData) => ({
            ...prevData,
            rows: jobsFetched,
            totalRows: jobsFetched?.length,
        }));
    };

    const handleConsentAction = async (statusConstaint, id) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "job_id":id,
                 "status":statusConstaint
            });
            const requestOptions: RequestInit = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            };
            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/job/update/status", requestOptions);
            const result = await response.json();
            if (result.status_code === 201 && result.success === true) {
              toast.success("Job status updated successfully");
              fetchJobs()
            }
          } catch (error) {
            toast.error(error.message);
          }
    }

    const collectionUserType = ["SUPER_ADMIN","CARRIER"];
    const canDeltaUploadPod = collectionUserType.includes(user?.type|| "");
    
    const columns: GridColDef[] = useMemo(() => [        
        { field: 'company', headerName: 'Company', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'customer', headerName: 'Customer', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'customer_reference', headerName: 'Customer Reference', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'trip_type', headerName: 'Trip Type', minWidth: 180, align: 'center', headerAlign: 'center' },        
        { field: 'movement_type_id', headerName: 'Movement Type', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'shipment_no', headerName: 'Shipment No', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'statusValue', headerName: 'Status', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'created_at', headerName: 'created At', minWidth: 180, align: 'center', headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 300,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleConsentAction("carrierConfirmed", params.row.id)}
                       disabled={params.row.status!=="pendingConfirmation"}
                       >
                            Accept
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleConsentAction("carrierDeclined", params.row.id)}
                            disabled={params.row.status!=="pendingConfirmation"}
                        >
                            Decline
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            onClick={() => { setAssignedData(params.row); toggleModal("modal4")}}
                        >
                            Preview
                        </Button>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'Upload POD', headerName: 'Upload POD', align: 'center', headerAlign: 'center', minWidth: 150, sortable: false,hide: !canDeltaUploadPod,
            renderCell: (params) => {
                const isPreviewAvailable = params.row.receipt_url?.length > 0
                return (
                    <div style={{ display: 'flex', gap: '10px', alignItems: "baseline", justifyContent: "center" }}>
                        <CloudUploadIcon
                            style={{ color: "#2563eb", cursor: "pointer" }}
                            fontSize='small'
                            onClick={() => { toggleModal("modal2"); setAssignedData(params.row); }}
                            aria-label="Upload Job POD"
                        />
                        {/* <RemoveRedEyeIcon
                            style={{
                                color: isPreviewAvailable ? "#ec4899" : "#94a3b8",
                                cursor: isPreviewAvailable ? "pointer" : "not-allowed",
                            }}
                            fontSize="small"
                            onClick={() => {
                                if (isPreviewAvailable) {
                                    setUploadedPodUlr(params.row.receipt_url);
                                    toggleModal("modal5");
                                }
                            }}
                        /> */}
                        <CloudDownloadIcon
                            onClick={() => isPreviewAvailable && handleDownload(params.row.receipt_url)}
                            style={{
                                color: !isPreviewAvailable ? "#94a3b8" : "#84cc16",
                                cursor: !isPreviewAvailable ? "not-allowed" : "pointer"
                            }}
                            fontSize="small"
                        />
                    </div>
                );
            },
        },
    ], []);  

    const toggleModal = useCallback(
        (modalName: keyof AdminModalsState) => {
            setModals((prevState) => ({
                ...prevState,
                [modalName]: !prevState[modalName],
            }));
        },
        [setModals]
    );

    const handleSearch = (query: string) => {        
        setSearch(query);
    };

    return (
        <>
        <UploadPodModal
                open={modals.modal2}
                close={() => toggleModal("modal2")}
                assignedData={assignedData}
                fetchJobs={fetchJobs}
            />
            <ViewJobModal    
             open={modals.modal4}
                close={() => toggleModal("modal4")}
                assignedData={assignedData}
            />
            <AssignJobModal
                open={modals.modal1}
                close={() => toggleModal("modal1")}
                assignedData={assignedData} fetchJobs={function (): void {
                    throw new Error('Function not implemented.');
                } }            />
            <SectionHeader subtitle="Transport Management" title="My Jobs" />
            <div className={classes.search}>
                <CustomSearchInput
                    label="Please enter job reference"
                    onSearch={handleSearch}
                    variant="outlined"
                    value={search}
                />
            </div>
            {data?.rows?.length === 0 && !loading && <div className={classes.errorMessage}>No jobs found.</div>}

            <div className={classes.root}>
                <DataGrid
                    className={classes.dataGrid}
                    onRowClick={props.onRowClick}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: 'No records found' }}
                    paginationMode="client"
                    sortingMode="client"
                    loading={loading}
                    rowCount={data.totalRows}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    page={data.page - 1}
                    pageSize={data.pageSize}
                    rows={data.rows}
                    onPageChange={(newPage) => { setData((prev) => ({ ...prev, page: newPage + 1 })); }}
                    onPageSizeChange={(newPageSize) => {
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
                    }}
                />
            </div>
        </>
    );
}


export default JobsToBeAssignedToCarrier