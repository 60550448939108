import { Button, Checkbox } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IAddress } from "../../../../../../Application/DTOs/AddressDto/AddressDto.types";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';


export const getColumns = (t: TFunction, onUpdateStatusCallback: (addressId: string | number, newStatus: boolean) => void, onDelete: (addressId: string | number) => void, isUserAdmin: boolean, setSelectedAddress: any, setAddressMode: any, setIsAddNewAddressDialogOpen: any): GridColumns => {


    return [
        {
            field: 'ship_id',
            headerName: t('Shipping Address ID'),
            width: 200,
            sortable: true,
        },
        // {
        //     field: 'country',
        //     headerName: t('country'),
        //     width: 200,
        //     valueGetter: (params) => t((params.row as IAddress).country)
        // },
        {
            field: 'company_name',
            headerName: t('company_name'),
            width: 250
        },
        {
            field: 'street_number',
            headerName: t('street_number'),
            width: 200
        },
        {
            field: 'city',
            headerName: t('suburb_city'),
            width: 200
        },
        {
            field: 'state',
            headerName: t('state'),
            width: 200
        },
        {
            field: 'actions',
            headerName: t('disabled'),
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const address = params.row as IAddress
                if (isUserAdmin) {
                    return <Checkbox color="primary" checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />
                }
                else {
                    if (address.is_common) {
                        return <Checkbox color="primary" disabled readOnly checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />
                    } else {
                        return <Checkbox color="primary" checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />


                    }
                }
            }
        }
        ,
        // {
        //     field: 'delete',
        //     headerName: t('delete'),
        //     width: 200,
        //     sortable: false,
        //     renderCell: (params) => {
        //         const address = params.row as IAddress
        //     if (isUserAdmin) {
        //         return <Button onClick={() => onDelete(address.id)} variant="contained" color="secondary">{t('delete')}</Button>
        //     }
        //     else {
        //         if (address.is_common) {
        //             return <Button disabled onClick={() => onDelete(address.id)} variant="contained" color="secondary">{t('delete')}</Button>
        //         } else {
        //             return <Button onClick={() => onDelete(address.id)} variant="contained" color="secondary">{t('delete')}</Button>

        //         }
        //     }
        // }
        // },








        {
            field: 'action', headerName: 'Action', align: 'center', headerAlign: 'center', minWidth: 200, sortable: false,
            renderCell: (params) => {

                const deleteIcon = () => {
                    if (isUserAdmin) {
                        return <DeleteIcon style={{ color: "#dc2626", cursor: "pointer" }}
                            fontSize='small'
                            aria-label="Delete Shipping Address"
                            onClick={() => onDelete(params?.row?.id)}

                            color="secondary">{t('delete')}</DeleteIcon>
                    }
                    else {
                        if (params.row.is_common) {

                            return <div

                            > <DeleteIcon

                                style={{

                                    color: params.row.is_common ? "gray" : "#dc2626",
                                    cursor: params.row.is_common ? "not-allowed" : "pointer",
                                }}
                                fontSize='small'
                                aria-label="Delete Shipping Address"


                                onClick={!params.row.is_common ? () => onDelete(params?.row?.id) : () => { }} color="secondary">{t('delete')}</DeleteIcon></div>
                        } else {
                            return <DeleteIcon
                                style={{ color: "#dc2626", cursor: "pointer" }}
                                fontSize='small'
                                aria-label="Delete Shipping Address"

                                onClick={() => onDelete(params?.row?.id)} color="secondary">{t('delete')}</DeleteIcon>

                        }
                    }
                }

                return (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }} >
                      
                        <EditIcon
                            aria-label="Edit Shipping Address"
                            style={{ color: "#64748b", cursor: "pointer" }}
                            fontSize='small'
                            onClick={() => {
                                setSelectedAddress(params?.row?.id);
                                setAddressMode('edit');
                                setIsAddNewAddressDialogOpen(true);
                            }}
                        />

                        {deleteIcon()}


                    </div>
                )
            }
        },
    ]
}