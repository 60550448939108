import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../../routes';
import CompanyShipping from '../../../ComponentsV2/pages/addressManagement/CompanyShipping';
import CustomerShipping from '../../../ComponentsV2/pages/addressManagement/CustomerShipping';
import WareHouseAddress from '../../../ComponentsV2/pages/addressManagement/WareHouseAddress';
import WharfAddress from '../../../ComponentsV2/pages/addressManagement/WharfAddress';

function AddressManagement() {
  const match = useRouteMatch();
  return (
    <React.Fragment>
      <Route path={match.path + routes.home.address_management.companyshippingaddresses}>
        <CompanyShipping  />
      </Route>
      <Route path={match.path + routes.home.address_management.customershippingaddresses}>
        <CustomerShipping  />
      </Route>
      <Route path={match.path + routes.home.address_management.wharfaddresses}>
        <WareHouseAddress  />
      </Route>
      <Route path={match.path + routes.home.address_management.warehouseaddresses}>
        <WharfAddress  />
      </Route>
    </React.Fragment>
  );
}

export default AddressManagement;
