import axios from "axios"
import { IAddressPayload, IUpdateAddressPayload } from '../../../../Application/DTOs/AddressDto/AddressDto.types'
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"
import { WebStorage } from "../../Utilities/WebStorage"

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()
export class Address {

    delete(addressId: string | number) {

        return axios.post(`${baseUrl}/address/delete`, { addressId }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    create(payload: IAddressPayload, isCommon: boolean = false) {

        return axios.post(`${baseUrl}/address/create`, payload, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    update(payload: IUpdateAddressPayload) {

        return axios.post(`${baseUrl}/address/update`, payload, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    all(companyId: number | string | undefined, isCommon: boolean = true, country: string | null, is_disabled?: boolean, isSea?: boolean, isLand?: boolean, hideDefinedPort?: boolean) {
        return axios.get(`${baseUrl}/address/all`, {
            params: {
                companyId,
                isCommon,
                country,
                is_disabled,
                hideDefinedPort,
                is_land_freight: isLand,
                is_sea_freight: isSea
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    byId(id: string) {
        return axios.get(`${baseUrl}/address/byid`, {
            params: {
                id
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    allForJob(companyId: number | string | undefined, isCommon: boolean = true, country: string | null, is_disabled?: boolean, isSea?: boolean, isLand?: boolean) {
        return axios.get(`${baseUrl}/address/job/all`, {
            params: {
                companyId,
                isCommon,
                country,
                is_disabled,
                is_land_freight: isLand,
                is_sea_freight: isSea
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateStatus(addressId: string | number, status: boolean) {
        return axios.post(`${baseUrl}/address/update/status`, { addressId, status }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getFilteredAddress(company_id?: number | string | undefined, state?: any, address_group?: any, is_common?: boolean, globalSearch?: any) {
        return axios.get(`${baseUrl}/address/list`, {
            params: {
                company_id,
                state,
                address_group,
                is_common,
                globalSearch
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }


    // /import/csv/customer


    importCustomerCsv(payload: any) {

        return axios.post(`${baseUrl}/address/import/csv/customer`, payload, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
}
