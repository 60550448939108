import React, { useState, useEffect } from 'react';
import ModalWrapper from '../wrappers/ModalWrapper';
import { makeStyles, CircularProgress } from '@material-ui/core';

interface PreviewPodProps {
  open: boolean;
  close: () => void;
  url: string;
}

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    width: '100%',
    height: '80vh', // Adjust as needed
    border: 'none',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const PreviewPod = ({ open, close, url }: PreviewPodProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => setLoading(false), 1000); // 1 second delay
    return () => clearTimeout(timer);
  }, [url]);

  const isImage = (url: string) => {
    return /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i.test(url);
  };

  return (
    <ModalWrapper open={open} onClose={handleClose} heading="Preview (POD)">
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : isImage(url) ? (
        <img
          src={url}
          alt="Preview"
          style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
        />
      ) : (
        <iframe
          src={url}
          className={classes.iframeContainer}
          title="Preview Content"
        />
      )}
    </ModalWrapper>
  );
};

export default PreviewPod;
