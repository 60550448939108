import { Grid, Button, FormControl, MenuItem, makeStyles, InputLabel, Select, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CustomSearchInput from '../../ui/CustomSearchInput';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import { IAddresManagementProps } from '../../../Components/Home/AdminSettings/AddressManagement/Address.props';
import { AppState } from '../../../Redux';
import { ADDRESS_GROUPS, AU_STATES, USER_ROLES } from '../../../Types/Constants';
import { setLoader } from '../../../Redux/Loader/actions';
import { Address } from '../../../Api/Address/Address';
import { IAddress } from '../../../../../Application/DTOs/AddressDto/AddressDto.types';
import CreateAddressDialog from '../../../Components/Dialogs/CreateAddressDialog/CreateAddressDialog';
import CustomTable from '../../../Components/Common/CustomTable/CustomTable';
import { GridColDef } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatString } from '../../lib/helper';

const addressApi = new Address()

const {
  ADMIN_USER,
  SUPER_ADMIN
} = USER_ROLES


const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  gap: {
    marginTop: 20,
  },
  selectLabel: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
  },
  btnCreate: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
}));


function WharfAddress(props: IAddresManagementProps) {
  const [t] = useTranslation('Address')
  const { accessType } = props
  const dispatch = useDispatch()
  const [isAddNewAddressDialogOpen, setIsAddNewAddressDialogOpen] = useState(false)
  const [addresses, setAddresses] = useState<IAddress[]>([])
  const user = useSelector((state: AppState) => state.user)
  const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user.response?.type)
  const [selectedAddress, setSelectedAddress] = useState<any>(undefined)
  let company: any = useSelector((state: AppState) => state.company);
  const [companyId, setCompanyId] = useState<any>('');
  const [addressState, setAddressState] = useState('');
  const [addressGroup, setAddressGroup] = useState('');
  const [addressMode, setAddressMode] = useState<'create' | 'edit' | string>('create');
  const [search, setSearch] = useState("");

  const classes = useStyles();
  let companyListOptions = useMemo(() =>
    company.list.map((company, i) => (
      <MenuItem key={i} value={company.id}>
        {company.companyName}
      </MenuItem>
    )),
    [company.list]
  );

  const handleOnApplyClear = () => {
    setCompanyId('')
    setAddressState('')
    setAddressGroup('')
    getAddresses('', '', '', search);
  }

  const getAddresses = useCallback((companyid, address_state, address_group, search) => {
    dispatch(setLoader(true))
    addressApi.getFilteredAddress(companyid, address_state, address_group, accessType == 'common', search)
      .then((response) => {
        const addresses = response.data as IAddress[]

        setAddresses(addresses)
        dispatch(setLoader(false))
      })
      .catch((err: any) => {
        console.log('err', err)
        toast.error(t('error_getting_addresses'))
        dispatch(setLoader(false))
      })
  }, [])

  const stateOptions = useMemo(
    () =>
      Object.keys(AU_STATES).map((state, i) => {
        if (t(state) != "") {
          return <MenuItem key={i} value={state}>
            {t(state)}
          </MenuItem>
        }
      }
      ),
    [AU_STATES, t]
  );

  const addressGroupOptions = useMemo(
    () =>
      Object.keys(ADDRESS_GROUPS).map((key, i) => (
        <MenuItem key={i} value={ADDRESS_GROUPS[key]}>
          {t(ADDRESS_GROUPS[key])}
        </MenuItem>
      )),
    [ADDRESS_GROUPS]
  );

  const handleOnAddNewAddressDialogClose = useCallback(
    () => {
      setIsAddNewAddressDialogOpen(false)
      setSelectedAddress(undefined)
      getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup, search)

    },
    [companyId, addressState, addressGroup],
  )

  const handleFilterButton = () => {
    getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup, search);
  }

  const handleOnAddAddressDone = useCallback(
    () => {
      handleOnAddNewAddressDialogClose()
    },
    [handleOnAddNewAddressDialogClose],
  )

  useEffect(() => {
    getAddresses(companyId, addressState, addressGroup, search);
    if (!isUserAdmin) {
      setCompanyId(user.response?.company.id)
    }
  }, [])

  const onUpdateStatusCallback = useCallback(
    (addressId: string | number, newStatus: boolean) => {
      addressApi.updateStatus(addressId, newStatus)
        .then(() => {
          getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup, search);

        })
        .catch((err: any) => {
          console.log('err', err)
          toast.error(t('error_updating_address_status'))
          dispatch(setLoader(false))
        })
    },
    [isUserAdmin, addressState, addressGroup],
  )

  const onDelete = useCallback(
    (addressId: string | number) => {


      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#303f9f",
        cancelButtonColor: "#f50057",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setLoader(true))

          addressApi.delete(addressId)
            .then(() => {
              dispatch(setLoader(false))
              getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup, search)

            })
            .catch((err: any) => {
              console.log('err', err)
              toast.error(t('error_deleting_address'))
              dispatch(setLoader(false))
            })
        }
        dispatch(setLoader(false))

      });

    },
    [],
  )

  const handleSearch = (query: string) => {
    setSearch(query);
  };

  useEffect(() => {
    getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup, search)
  }, [search])


  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'ship_id',
      headerName: t('Shipping Address ID'),
      width: 200,
      sortable: true,
    },
    {
      field: 'company_name',
      headerName: t('company_name'),
      width: 250
    },
    {
      field: 'street_number',
      headerName: t('street_number'),
      width: 200
    },
    {
      field: 'city',
      headerName: t('suburb_city'),
      width: 200
    },
    {
      field: 'state',
      headerName: t('state'),
      width: 200
    },
    {
      field: 'address_group', headerName: 'Group Type',width: 200,
      renderCell: (params) => (
          <div>
              {formatString(params.row.address_group)}
          </div>
      )
  },
    {
      field: 'actions',
      headerName: t('disabled'),
      width: 200,
      sortable: false,
      hide: !(user.response.type === "SUPER_ADMIN" || user.response.type === "ADMIN_OPERATIONS"),
      renderCell: (params) => {
        const address = params.row as IAddress
        if (isUserAdmin) {
          return <Checkbox color="primary" checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />
        }
        else {
          if (address.is_common) {
            return <Checkbox color="primary" disabled readOnly checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />
          } else {
            return <Checkbox color="primary" checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />


          }
        }
      }
    },
    {
      field: 'action', 
      headerName: 'Action', 
      align: 'center', 
      headerAlign: 'center', 
      minWidth: 200, 
      sortable: false,
      hide: !(user.response.type === "SUPER_ADMIN" || user.response.type === "ADMIN_OPERATIONS"),
      renderCell: (params) => {
        const deleteIcon = () => {
          if (isUserAdmin) {
            return <DeleteIcon style={{ color: "#dc2626", cursor: "pointer" }}
              fontSize='small'
              aria-label="Delete Shipping Address"
              onClick={() => onDelete(params?.row?.id)}

              color="secondary">{t('delete')}</DeleteIcon>
          }
          else {
            if (params.row.is_common) {
              return <div>
                <DeleteIcon
                  style={{
                    color: params.row.is_common ? "gray" : "#dc2626",
                    cursor: params.row.is_common ? "not-allowed" : "pointer",
                  }}
                  fontSize='small'
                  aria-label="Delete Shipping Address"
                  onClick={!params.row.is_common ? () => onDelete(params?.row?.id) : () => { }} color="secondary">{t('delete')}</DeleteIcon></div>
            } else {
              return <DeleteIcon
                style={{ color: "#dc2626", cursor: "pointer" }}
                fontSize='small'
                aria-label="Delete Shipping Address"
                onClick={() => onDelete(params?.row?.id)} color="secondary">{t('delete')}</DeleteIcon>
            }
          }
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }} >
            <EditIcon
              aria-label="Edit Shipping Address"
              style={{ color: "#64748b", cursor: "pointer" }}
              fontSize='small'
              onClick={() => {
                setSelectedAddress(params?.row?.id);
                setAddressMode('edit');
                setIsAddNewAddressDialogOpen(true);
              }}
            />
            {deleteIcon()}
          </div>
        )
      }
    },
  ], []);

  let newAddress=addresses?.length>0 ?addresses?.filter((item)=> item?.address_group == "dc_or_warehouse"):[]

  return (
    <>
      <CreateAddressDialog mode={addressMode} address={selectedAddress} onDone={handleOnAddAddressDone} isCommon={accessType == 'common'} open={isAddNewAddressDialogOpen} handleClose={handleOnAddNewAddressDialogClose} />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={5}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px', alignItems: 'center' }}>
          <SectionHeader subtitle="Wharf Shipping Addresses" title={"Wharf Address"} />

          <CustomSearchInput label="Search Address"
            onSearch={handleSearch}
            value={search}
            variant="outlined"
          />
        </div>
        <Grid item>
          <Grid container spacing={2}>
            <Grid container style={{ "paddingBottom": "10px" }} spacing={2} md={12}>
              <Grid item container style={{ "alignItems": 'center' }} spacing={1} md={9}>
                {(isUserAdmin && accessType != 'common') ?
                  <Grid item md={3}>
                    <FormControl style={{ backgroundColor: 'white' }} variant="filled" className={classes.formControl}>
                      <InputLabel className={classes.selectLabel}>
                        {t('select_company') + '*'}
                      </InputLabel>
                      <Select
                        value={companyId}
                        onChange={(e) => {
                          const companyId = e.target.value;
                          setCompanyId(companyId as string);
                        }}
                        style={{ backgroundColor: 'white' }}
                      >
                        {companyListOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                  :
                  null
                }

                <Grid item md={3}>
                  <FormControl
                    variant="filled"
                    style={{ backgroundColor: 'white' }}
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {t('pickup_select_state')}
                    </InputLabel>
                    <Select
                      value={addressState}
                      onChange={(e) => {
                        setAddressState(e.target.value as any);
                      }}
                      style={{ backgroundColor: 'white' }}
                    >
                      {stateOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container spacing={2} md={3}>
                  <Grid item md={6} >
                  <Button
                    fullWidth
                    onClick={handleFilterButton}
                    color="primary"
                    variant="contained"
                  >
                    {t('filter')}
                  </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ "alignItems": 'center', "justifyContent": "right", alignContent: 'center', gap: '15px' }} spacing={1} md={3}>
                <Grid item md={6} >
                  <Button fullWidth style={{ backgroundColor: 'white' }} onClick={handleOnApplyClear} color="default" variant="contained">
                      Clear
                    </Button>
                </Grid>

              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid item md={12}>
                <CustomTable
                  rows={newAddress}
                  columns={columns}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default WharfAddress