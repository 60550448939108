import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import useDebounce from '../../hooks/useDebounce';
import { Box, Button } from '@material-ui/core';
import { fetchJobsList } from '../../lib/helper';
import CustomDatePickerNew from '../../ui/CustomDatePickerNew';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ViewJobModal from '../../modals/ViewJobModal';
import { AdminModalsState } from './JobsToBeAssignedV2';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Redux';
import CancelIcon from '@material-ui/icons/Cancel';
import CancelJobModal from '../../modals/CancelJobModal';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    dataGrid: {
        minWidth: "fit-content",
        '& .MuiDataGrid-menuIconButton': {
            color: 'white',
        },
    },
    searchContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
    },
    dateContainer: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    button: {
        height: '100%',
        whiteSpace: 'nowrap',
        width: '80%',
    },
    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
    },
    actions: {
        display: "flex !important",
        gap: "12px !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        width: "100% !important",
    },
    dropdown_container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        marginBottom: "6px !important"
    },

}));

const storage = new WebStorage();

const PendingJobsCompany = (props: any) => {
    const classes = useStyles();
    const user = useSelector((state: AppState) => state.user.response);
    const [loading, setLoading] = useState<boolean>(false);
    const [assignedData, setAssignedData] = useState(null);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);
    const [modals, setModals] = useState<AdminModalsState>({       
        modal4: false,   //view modal
        modal5: false,  //cancel job
    });
    const [data, setData] = useState({
        loading: true,
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
        rows: [],
    });

    const token = `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchJobs();
    }, [debouncedSearch, fromDate, toDate]);

    const fetchJobs = async () => {
        const jobsFetched = await fetchJobsList("pending", fromDate, toDate, debouncedSearch, setLoading, token);
        setData((prevData) => ({
            ...prevData,
            rows: jobsFetched,
            totalRows: jobsFetched?.length,
        }));
    };

    const columns: GridColDef[] = useMemo(() => [
        { field: 'company', headerName: 'Company', align: 'center', headerAlign: 'center', flex:1 },
        { field: 'customer', headerName: 'Customer', align: 'center', headerAlign: 'center', flex: 1 },
        { field: 'movement_type_id', headerName: 'Movement Type', align: 'center', headerAlign: 'center', flex: 1 },
        { field: 'created_at', headerName: 'Created At', align: 'center', headerAlign: 'center', flex: 1 },
        {
            field: 'action', headerName: 'Action', align: 'center', headerAlign: 'center', flex:1, sortable: false,
            renderCell: (params) => {
                const allowedUserRoles = ["SUPER_ADMIN","COMPANY","COMPANY USER"];
                const isUserAllowed = allowedUserRoles.includes(user.type);
                return (
                    <div className={classes.actions}>
                        <RemoveRedEyeIcon
                            onClick={() => { setAssignedData(params.row); toggleModal("modal4") }}
                            style={{ color: "#84cc16", cursor: "pointer" }}
                            fontSize='small'
                            aria-label="View Job"
                        />
                        {isUserAllowed ? <CancelIcon
                            onClick={() => { setAssignedData(params.row); toggleModal("modal5") }}
                            style={{ color: "#fbbf24", cursor: "pointer" }}
                            fontSize='small'
                            aria-label="Cancel Job"
                        /> : <></>}
                    </div>
                );
            },
        },
    ], []);

    const toggleModal = useCallback(
        (modalName: keyof AdminModalsState) => {
            setModals((prevState) => ({
                ...prevState,
                [modalName]: !prevState[modalName],
            }));
        },
        [setModals]
    );

    const handleSearch = (query: string) => {
        setSearch(query);
    };

    const handleClearDates = useCallback(() => {
        if (fromDate || toDate || search) {
            setFromDate(null);
            setToDate(null);
            setSearch('');
        }
    }, [fromDate, toDate, search]);

    return (
        <>
            <ViewJobModal
                open={modals.modal4}
                close={() => toggleModal("modal4")}
                assignedData={assignedData}
            />
            <CancelJobModal
                open={modals.modal5}
                close={() => toggleModal("modal5")}
                assignedData={assignedData}
                fetchJobs={fetchJobs}
            />
            <SectionHeader subtitle="Transport Management" title="Pending Jobs" />
            <Box className={classes.searchContainer}>
                <Box className={classes.dateContainer}>
                    <CustomDatePickerNew
                        selectedDate={fromDate}
                        handleDateChange={(date: string) => setFromDate(date)}
                        name="fromDate"
                        placeholder="From"
                    />
                    <CustomDatePickerNew
                        selectedDate={toDate}
                        handleDateChange={(date: string) => setToDate(date)}
                        name="toDate"
                        placeholder="To"
                        minDate={fromDate}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        onClick={handleClearDates}
                        disabled={!fromDate && !toDate && search === ''}
                    >
                        Clear Filters
                    </Button>
                </Box>
                <CustomSearchInput label="Search by reference" onSearch={handleSearch} variant="outlined" value={search} />
            </Box>
            {data?.rows?.length === 0 && !loading && <div className={classes.errorMessage}>No jobs found.</div>}

            <div className={classes.root}>
                <DataGrid
                    className={classes.dataGrid}
                    onRowClick={props.onRowClick}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: 'No records found' }}
                    paginationMode="client"
                    sortingMode="client"
                    loading={loading}
                    rowCount={data.totalRows}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    page={data.page - 1}
                    pageSize={data.pageSize}
                    rows={data.rows}
                    onPageChange={(newPage) => { setData((prev) => ({ ...prev, page: newPage + 1 })); }}
                    onPageSizeChange={(newPageSize) => {
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
                    }}
                    autoHeight
                />
            </div>
        </>
    );
}

export default PendingJobsCompany;
