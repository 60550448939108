import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core'
import { PRIMARY_COLOR, TERTIARY_COLOR } from '../../../Styles/colors';
import { ICustomTableProps } from './CustomTable.types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    height: 700,
    minWidth: 800,
    // '& .MuiDataGrid-columnsContainer': {
    //   backgroundColor: PRIMARY_COLOR + '55',
    // },
    // '& .MuiDataGrid-colCellTitle': {
    //   fontWeight: 'bolder'
    // }
  }
}))

function CustomTable(props: ICustomTableProps) {
  const classes = useStyles()
  const [t] = useTranslation()
  const noRowsLabel = t('no_rows')
  return (
    <div className={classes.root}>
      <DataGrid
        onRowClick={props.onRowClick}
        rows={props.rows}
        columns={props.columns}
        pageSize={props.pageSize}
        disableSelectionOnClick
        sortingOrder={['asc','desc']}
        rowsPerPageOptions={[5, 10, 20]}
        localeText={{
          noRowsLabel
        }}
        onCellClick={(params, event) => {
          if (['status', 'action', 'delete', 'actions'].includes(params.field)) {
            event.stopPropagation()
          }
        }}
        disableColumnMenu
      />
    </div>
  );
}

export default CustomTable