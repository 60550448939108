import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from 'react';
import { checkValue, formatString } from "../lib/helper";
interface Props {
    data?: any;
}

const OthersTable = ({ data }: Props) => {
    const pdfRef = useRef<HTMLDivElement>(null);

    if (!data) {
        return null;
    }

    const fields = [
        { label: "Company", value: checkValue(data.company)},
        { label: "Movement Type", value: formatString(data.movement_type)},
        { label: "Shipment No.", value: checkValue(data.shipment_no)},
        { label: "Customer", value: checkValue(data.customer)},
        { label: "Job Type", value: formatString(data.job_type)},
        { label: "MI4U Ref No.", value: checkValue(data.reference)},
        //{ label: "Customer Ref.", value: checkValue(data.customer_reference)},
        { label: "Trip Type", value: formatString(data.trip_type)},
        { label: "Transport Type", value: formatString(data.transport_type)},
        { label: "Pickup Vehicle Type", value: formatString(data.pickup_vehicle_type)},
        { label: "Pickup Group Type", value: formatString(data.pickup_group)},
        { label: "Pickup Country", value: formatString(data.pickup_country)},
        { label: "Pickup State", value: checkValue(data.pickup_state)},
        { label: "Pickup Ship Id", value: checkValue(data.pickup_address_id)},
        { label: "Pickup Address", value: checkValue(data.pickup_location)},
        { label: "Pickup Date", value: checkValue(data.pickup_date)},
        { label: "Pickup Time", value: checkValue(data.pickup_time)},
        //{ label: "Pickup Job Requirement", value: checkValue(data.pickup_job_requirement)},
        { label: "Deliver Vehicle Type", value: formatString(data.delivery_vehicle_type)},
        { label: "Deliver Group Type", value: formatString(data.delivery_group)},
        { label: "Deliver Country", value: formatString(data.delivery_country)},
        { label: "Deliver State", value: checkValue(data.delivery_state)},
        { label: "Deliver Ship Id", value: checkValue(data.delivery_address_id)},
        { label: "Deliver Ship Address", value: checkValue(data.delivery_location)},
        { label: "Deliver Date", value: checkValue(data.delivery_date)},
        { label: "Deliver Time", value: checkValue(data.delivery_time)},
        //{ label: "Deliver Job Requirement", value: checkValue(data.delivery_job_requirement)},
       // { label: "Temperature", value: checkValue(data.temperature)},
       // { label: "Temperature Instructions", value: checkValue(data.temperature_instructions)},
        { label: "Total Qty", value: checkValue(data.total_qty)},
        { label: "Total Net Weight (kg)", value: checkValue(data.total_net_weight)},
        { label: "Total Gross Weight (kg)", value: checkValue(data.total_gross_weight)},
        { label: "Volume", value: checkValue(data.volume)},
        { label: "Goods Description", value: checkValue(data.good_description)},
        { label: "Pallet Account Type", value: checkValue(data.pallet_account_type)},
        { label: "Pallet Account Action", value: formatString(data.pallet_account_number)},
        { label: "Service Type", value: checkValue(data.service_type)},
        //{ label: "Post To", value: formatString(data.post_to)},
       // { label: "Buy Price", value: checkValue(data.buy_price)},
       // { label: "Sell Price", value: checkValue(data.sell_price)},
        //{ label: "Job Instructions", value: checkValue(data.job_instructions)},
        { label: "Created At", value: checkValue(data.created_at)},
    ];

    const generatePDF = async () => {
        if (!pdfRef.current) return;

        const canvas = await html2canvas(pdfRef.current, { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        const filename = `${data.company} Job.pdf`.replace(/\s+/g, '_');
        pdf.save(filename);
    };

    return (
        <>
            <div ref={pdfRef} style={{ padding: "16px" }}>
                <Grid container spacing={2}>
                    {fields.map((field, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Typography variant="subtitle2" color="textSecondary">
                                {field.label}
                            </Typography>
                            <Typography variant="body1">{field.value}</Typography>
                        </Grid>
                    ))}
                </Grid>

                {data.job_details?.length > 0 && <Divider style={{ margin: '20px 0' }} />}

                {data.job_details && data.job_details?.length > 0 && (
                    <Box marginTop={2}>
                        {data.job_details?.map((detail: any, index: number) => (
                            <Box key={index} marginBottom={3}>
                                <Typography variant="subtitle1" color="primary">
                                    Job Detail {index + 1}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Package Type
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_package)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Quantity
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_qty)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            SKU
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_sku)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Description
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_desc)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Lot
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_lot)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Height (ft)
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_height)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Width (ft)
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_width)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Length (ft)
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_length)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Net Weight (kg)
                                        </Typography>
                                        <Typography variant="body1">{checkValue(detail.job_detail_net_weight)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                )}
            </div>

            <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={generatePDF}
            >
                Download PDF
            </Button>
        </>
    );
};

export default OthersTable;