import { makeStyles, Container, Grid, Typography, Box, TextField, InputAdornment, Button } from "@material-ui/core"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import moveit4u_logo from '../../../Images/moveit4u-logo.png'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { UsersApi } from "../../../Api/Users/Users";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import isEmail from "validator/lib/isEmail";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
    box: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)'
    },
    center: {
        textAlign: 'center'
    },
    logo: {
        width: '150px',
        height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    btnBack: {
        backgroundColor: 'grey',
        color: 'white',
        '&:hover': {
          backgroundColor: 'grey',
        },
      },
}))

const userApi = new UsersApi()

function ForgotPasswordEmail() {
    const [t] = useTranslation('ForgotPasswordEmail')
    const history = useHistory()
    const dispatch = useDispatch()
    const classes = useStyles()
    const [email, setEmail] = useState('')

    const handleBackButtonClick = () => {
        history.push(routes.landing.login[0]);
      };

    const handleOnSendEmailClick = useCallback(
        () => {
            if(email === '') {
                toast.warning(t('email_not_empty'))
                return
            } else if(email !== '' && !isEmail(email)) {
                toast.warning(t('email_invalid_format'))
                return
            }
            dispatch(setLoader(true))
            userApi.sendForgottenPasswordEmail(email)
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_sending_email'))
                    history.push(routes.landing.login[0])
                })
                .catch(err => {
                    console.log(`err`, err)
                    toast.error(t(err.response?.data || 'error_sending_email'))
                    dispatch(setLoader(false))
                })
        },
        [email, dispatch, t, history],
    )

    return (
        <>
            <Container maxWidth="sm">
                <Box className={classes.box}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid xs={12} item>
                            <img
                                className={classes.logo}
                                title="Moveit4u"
                                src={moveit4u_logo}
                                alt="Moveit4u"
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Typography variant="h6" className={classes.center} gutterBottom>
                                {t('please_enter_your_email')}
                            </Typography>
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('email_address')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid className={classes.center} item xs={12}>
                            <Button onClick={handleOnSendEmailClick} fullWidth variant="contained" color="primary">
                                {t('send_email')}
                            </Button>
                        </Grid>
                        <Grid md={6} item>
                    <Button
                      startIcon={<ArrowBackIcon />}
                      className={classes.btnBack}
                      variant="contained"
                      fullWidth
                      onClick={handleBackButtonClick}
                    >
                      {'Back to Login in' }
                    </Button>
                  </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default ForgotPasswordEmail