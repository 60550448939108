import { format } from "date-fns";
import { toast } from "react-toastify";

export const Mi4uRefNumFunc = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); 
    let day = String(date.getDate()).padStart(2, '0');
    let newDate = `${year}${month}${day}`;

    let text = "";
    const possible = "0123456789";
    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return `MI4U-${newDate}-${text}`;
}


export const getTodayDate = () => {
    const today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    const yyyy = today.getFullYear();
    return new Date(`${mm}/${dd}/${yyyy}`);
  };

 export function formatDateToMMDDYYYY(dateString: string): string {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return '';
    }
  
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }


  export const checkIsEmpty=(val:string)=>{
    return val===""?null:val
  }

  export const checkValue=(val:any)=>{
    return val?val:"NA"
  }
  
  export const formatString = (input?: string): string => {
    if (!input) {
        return "";
    } else {
        return input
            ?.replaceAll("_", " ") 
            ?.split(" ")          
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
            ?.join(" ");          
    }
};

export const removeSFormString = (input? : string) : string  => {
    if (input.endsWith('s')) {
      return input.slice(0, -1);
    }
    return input;
};


export const handleDownload = (url: string) => {
  if (!url) return;

  const newTab = window.open(url, '_blank');
  
  if (newTab) {
      newTab.location.href = url;
  }
};


export const fetchJobsList = async (
  section: string,
  from: string | null,
  to: string | null,
  debouncedSearch: string,
  setLoading: (loading: boolean) => void,
  token: string
) => {
  setLoading(true);

  try {
      const myHeaders = new Headers({
          Authorization: token,
      });

      const formattedFromDate = from ? format(new Date(from), 'dd/MM/yyyy') : null;
      const formattedToDate = to ? format(new Date(to), 'dd/MM/yyyy') : null;

      const filters = JSON.stringify({
          reference: debouncedSearch,
          createdDate: { to: formattedToDate, from: formattedFromDate }
      });
      const encodedFilters = encodeURIComponent(filters);

      const requestOptions: RequestInit = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
      };

      const response = await fetch(
          `${process.env.REACT_APP_NEW_BASE_URL}/v1/job/get/optimize?classification=transport&page=${section}&offset=0&filters=${encodedFilters}`,
          requestOptions
      );

      if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();

      const formattedRows = result?.jobList?.map((job: any) => {
          const pickupAddress = job.job_addresses?.[0]?.pickupAddress || {};
          const deliveryAddress = job.job_addresses?.[job.job_addresses.length - 1]?.deliveryAddress || {};

          return {
              ...job,
              company: job.company?.name,
              customer:job?.customerData
              ? `${job.customerData.first_name} ${job.customerData.last_name}`
              : "NA",
              pickup_vehicle_type: job.job_addresses?.[0]?.pickup_vehicle_type,
              pickup_group: job.job_addresses?.[0]?.pickup_group,
              pickup_country: pickupAddress.country,
              pickup_state: pickupAddress.state,
              pickup_address_id: job.job_addresses?.[0]?.pickupAddress?.ship_id,
              pickup_location: pickupAddress
                  ? `${pickupAddress.company_name} - ${pickupAddress.street_number}, ${pickupAddress.city}, ${pickupAddress.state}, ${pickupAddress.postcode}`
                  : '',
              pickup_date: formatDateToMMDDYYYY(job.job_addresses[0]?.pickup_date_time?.split("T")[0]) ?? '',
              pickup_time: job.job_addresses[0]?.pickup_date_time?.split("T")[1]?.slice(0, 5) ?? '',
              pickup_job_requirement: job.job_addresses[0]?.pickup_job_requirement,
              delivery_vehicle_type: job.job_addresses?.[job.job_addresses.length - 1]?.delivery_vehicle_type,
              delivery_group: job.job_addresses?.[job.job_addresses.length - 1]?.delivery_group,
              delivery_country: deliveryAddress.country,
              delivery_state: deliveryAddress.state,
              delivery_address_id: job.job_addresses?.[job.job_addresses.length - 1]?.deliveryAddress?.ship_id,
              delivery_location: deliveryAddress
                  ? `${deliveryAddress.company_name} - ${deliveryAddress.street_number}, ${deliveryAddress.city}, ${deliveryAddress.state}, ${deliveryAddress.postcode}`
                  : '',
              delivery_date: formatDateToMMDDYYYY(job.job_addresses[job.job_addresses.length - 1]?.delivery_date_time?.split("T")[0]) ?? '',
              delivery_time: job.job_addresses[job.job_addresses.length - 1]?.delivery_date_time?.split("T")[1]?.slice(0, 5) ?? '',
              movement_type_id: removeSFormString(formatString(job.movement_type)),
              service_type: formatString(job.service_type),
              carrier: job?.assigned_user?.accountcontactname,
              scheduled_collection_date: formatDateToMMDDYYYY(job.job_addresses[0]?.pickup_date_time?.split("T")[0]) ?? '',
              actual_collection_date: formatDateToMMDDYYYY(job?.actual_collection_date?.split("T")[0]) ?? '',
              actual_collection_time: job?.actual_collection_time,
              scheduled_delivery_date: formatDateToMMDDYYYY(job.job_addresses[job.job_addresses.length - 1]?.delivery_date_time?.split("T")[0]) ?? '',
              actual_delivery_date: formatDateToMMDDYYYY(job?.actual_delivery_date?.split("T")[0]),
              actual_delivery_time: job?.actual_delivery_time,
              created_at: formatDateToMMDDYYYY(job.created_at?.split("T")[0]),
              statusValue: job?.statusValue,
              trip_type : formatString(job.trip_type),
          };
      });

      return formattedRows;
  } catch (err) {
      console.error('Fetch error:', err);
  } finally {
      setLoading(false);
  }
};


export const handleChangeStatus = async (statusContraint: string, id: string | number,token:string,fetchJobs:()=>void) => {
  try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
          "job_id": id,
          "status": statusContraint
      });

      const requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
      };

      const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/job/update/status", requestOptions);
      const result = await response.json();

      if (result.status_code === 201 && result.success === true) {
          toast.success("Job status updated successfully");
          fetchJobs()
      }
  } catch (error) {
      toast.error(error.message);
  }
}

export const resetFilterCompanyChange = async(setFieldValue: (field: string, value: any) => void, tripsLength: number) => {
    const resetValues = {};
    
    for (let i = 0; i < tripsLength; i++) {
      resetValues[`trip.${i}.tripPickState`] = "";
      resetValues[`trip.${i}.tripPickShipId`] = "";
      resetValues[`trip.${i}.tripPickAddress`] = "";
      resetValues[`trip.${i}.tripPickGroupType`] = "";
      resetValues[`trip.${i}.tripPickVehicleType`] = "";
      resetValues[`trip.${i}.tripDeliverVehicleType`] = "";
      resetValues[`trip.${i}.tripDeliverGroupType`] = "";
      resetValues[`trip.${i}.tripDeliverState`] = "";
      resetValues[`trip.${i}.tripDeliverShipId`] = "";
      resetValues[`trip.${i}.tripDeliverAddress`] = "";
    }
    
    Object.keys(resetValues).forEach(field => {
      setFieldValue(field, resetValues[field]);
    });
  };      
