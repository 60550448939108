
import ModalWrapper from '../wrappers/ModalWrapper'
import { toast } from 'react-toastify';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';
import { Button, makeStyles } from '@material-ui/core';
import { useState } from 'react';

interface DeleteJobModalProps {
  open: boolean;
  close: () => void;
  fetchJobs: () => void;
  assignedData?: any
}

const useStyles = makeStyles((theme) => ({
  deletePara: {
    fontSize: "18px",
    padding:"0",
    margin:"0"
  },
}));


const storage = new WebStorage();

const DeleteJobModal = ({ open, close, assignedData, fetchJobs }: DeleteJobModalProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions: RequestInit = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${process.env.REACT_APP_NEW_BASE_URL}/v3/jobs/${assignedData?.id}`, requestOptions);
      const result = await response.json();
      console.log(result, "result");

      if (result.status_code === 200 && result.success === true) {
        toast.success("Job deleted successfully");
        fetchJobs();
        handleClose();
      } else {
        toast.error(result?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    close();
  };

  return (
    <>

      <ModalWrapper
        open={open}
        onClose={handleClose}
        heading="Delete Job"
        width='650px'
      >
        <p className={classes.deletePara}>Are you sure you want to delete this job?</p>
        <Button
          type="button"
          fullWidth
          color="secondary"
          variant="contained"
          style={{ margin: '20px 0' }}
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </ModalWrapper>

    </>
  )
}

export default DeleteJobModal