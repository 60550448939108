// CountrySelect.js
import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button, makeStyles, Grid, TextField, InputAdornment } from "@material-ui/core";
import { COUNTRIES, ADDRESS_GROUPS, USER_ROLES, AU_STATES } from "../../../Types/Constants";

const { PHILIPPINES, AUSTRALIA, JAPAN, SINGAPORE, NEW_ZEALAND } = COUNTRIES

const useStyles = makeStyles(() => ({
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    formControl: {
        minWidth: '100%',
    }
}))

const CustomerSelect = ({ data,value, onChange, disabled, isPreview, isEditable, t }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth style={{backgroundColor:'white'}} variant="outlined" >
            {/* <InputLabel style={{ fontWeight: 'bold' }}>{t('Select your customer')}</InputLabel> */}

            <InputLabel style={{ fontWeight: 'bold' }}>Company/Customer ID</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={t('select_your_customer')}
                disabled={isPreview && !isEditable}

            >
                  <MenuItem  >
        {'Select Customer'}
    </MenuItem>
                {data}
            </Select>
        </FormControl>
    );
};


export const TimeslotRequiredSelect = ({ value, onChange, disabled, isPreview, isEditable, t }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" >
            <InputLabel style={{ fontWeight: 'bold' }}>{t('Timeslot')}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={t('select_your_Timeslot')}
                disabled={isPreview && !isEditable}

            >
                <MenuItem value="">
                    <em>Select {t('Timeslot')}</em>
                </MenuItem>
                <MenuItem id='no' value='No'>No</MenuItem>
                <MenuItem id='yes' value='Yes'>Yes</MenuItem>
            </Select>
        </FormControl>
    );
};



export const PalletRestrictions = ({ value, onChange, disabled, isPreview, isEditable, t }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" >
            <InputLabel style={{ fontWeight: 'bold' }}>{t('Pallet Restrictions')}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={t('Pallet Restrictions')}
                disabled={isPreview && !isEditable}

            >
                <MenuItem value="">
                    <em>Select {t('Pallet Restrictions')}</em>
                </MenuItem>
                <MenuItem id='no' value='No'>No</MenuItem>
                <MenuItem id='yes' value='Yes'>Yes</MenuItem>
            </Select>
        </FormControl>
    );
};
export default CustomerSelect;
