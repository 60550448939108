import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ITermsOfServiceDialogProps } from "./TermsOfServiceDialog.types";
import { TOSPH } from './TOS.PH';
import { TOSJP} from './TOS.JP';
import { TOSSG } from './TOS.SG';
import { TOSAU} from './TOS.AU'; 
import { COUNTRIES } from "../../../Types/Constants";

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE} = COUNTRIES

function TermsOfServiceDialog(props: ITermsOfServiceDialogProps) {
    const [t] = useTranslation('TermsOfServiceDialog')
    const {open, handleClose, country, agreed, onCheckBoxCheck} = props

    return (
        <>
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogActions>
                    <Button onClick={handleClose}>
                        X
                    </Button>
                </DialogActions>
                <DialogTitle>
                    {/* {t(`${country}_terms_of_service`)} */}
                    {`MOVEIT4U SOUTH AUSTRALIA PTY LIMITED(ABN 20 633 624 370)`}
                </DialogTitle>
                <DialogTitle>
                    {t('terms_and_conditions')}
                </DialogTitle>
                <DialogContent>
                    {
                        country === AUSTRALIA 
                        ? <TOSAU />
                        : null
                    }

                    {
                        country === PHILIPPINES 
                        ? <TOSPH />
                        : null
                    }

                    {
                        country === JAPAN 
                        ? <TOSJP />
                        : null
                    }

                    {
                        country === SINGAPORE 
                        ? <TOSSG />
                        : null
                    }
                    <TOSSG />
                </DialogContent>
                <DialogActions>
                    <Checkbox color="primary" checked={agreed} onChange={(e) => onCheckBoxCheck(e.target.checked)}/>
                    <Button disabled={!agreed} onClick={handleClose}>
                        {t('i_agree')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TermsOfServiceDialog