import ModalWrapper from '../wrappers/ModalWrapper'
import { toast } from 'react-toastify';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';
import { Button, makeStyles } from '@material-ui/core';
import { useState } from 'react';

interface CancelJobModalProps {
  open: boolean;
  close: () => void;
  fetchJobs: () => void;
  assignedData?: any
}

const useStyles = makeStyles((theme) => ({
  deletePara: {
    fontSize: "18px",
    padding: "0",
    margin: "0"
  },
}));


const storage = new WebStorage();

const CancelJobModal = ({ open, close, assignedData, fetchJobs }: CancelJobModalProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "job_id": assignedData?.id,
        "status": "cancelled"
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/job/update/status", requestOptions);
      const result = await response.json();

      if (result.status_code === 201 && result.success === true) {
        toast.success("Job cancelled successfully");
        fetchJobs();
        handleClose();
      } else {
        toast.error(result?.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }

  };

  const handleClose = () => {
    close();
  };

  return (
    <>

      <ModalWrapper
        open={open}
        onClose={handleClose}
        heading="Cancel Job"
        width='650px'
      >
        <p className={classes.deletePara}>Are you sure you want to cancel this job?</p>
        <Button
          type="button"
          fullWidth
          color="secondary"
          variant="contained"
          style={{ margin: '20px 0' }}
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? "Cancelling..." : "Cancel"}
        </Button>
      </ModalWrapper>

    </>
  )
}

export default CancelJobModal